import React from "react";

 
 const position = [51.505, -0.09]

const WhereWeOperate = () => {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63465.3543179687!2d-1.6674801999999997!3d6.1863003999999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdc6f9ea8742a7d%3A0x65d180fbb6ba5779!2sObuasi!5e0!3m2!1sen!2sgh!4v1677919127321!5m2!1sen!2sgh"
      height="450"
      style={{ border: 0, width: "100%", marginTop:"135px" }}
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    >
    </iframe>
  );
};

export default WhereWeOperate;


 {/* <div>
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63465.3543179687!2d-1.6674801999999997!3d6.1863003999999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdc6f9ea8742a7d%3A0x65d180fbb6ba5779!2sObuasi!5e0!3m2!1sen!2sgh!4v1677919127321!5m2!1sen!2sgh"
    height="450"
    style={{ border: 0, width: "100%" }}
    allowfullscreen=""
    loading="lazy"
    referrerpolicy="no-referrer-when-downgrade"
  >
  </iframe>
</div> */}